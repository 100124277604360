import React, { useState, useEffect } from "react";

import { gql, useLazyQuery } from "@apollo/client";
import Layout from "../../components/Layout";
import { Link } from "gatsby";
import RuntimeProductImage from "../../components/RuntimeProductImage";
import Loader from "../../components/Loader";
import { isBrowser } from "../../components/Auth";

const GET_PRODUCTS = gql`
  query SEARCH_QUERY($cursor: String, $query: String, $language: LanguageCode) @inContext(language: $language) {
    products(first: 10, after: $cursor, query: $query) {
      nodes {
        id
        title
        description
        handle
        availableForSale
        compareAtPriceRange {
          minVariantPrice {
            amount
          }
          maxVariantPrice {
            amount
          }
        }
        featuredImage {
          altText
          originalSrc
          width
          height
          url
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

const Search = () => {
  const [cursor, setCursor] = useState(null);
  const [title, setTitle] = useState("");
  const [queryTitle, setQueryTitle] = useState("");

  const [lang, setLang] = useState(null);
  useEffect(() => {
    if (isBrowser) {
      setLang(window.localStorage.getItem("langSelect"))
    }
  }, [])
  const [search, { data, loading, fetchMore }] = useLazyQuery(GET_PRODUCTS, {
    variables: {
      cursor: cursor,
      query: queryTitle,
      language: lang
    },
    fetchPolicy: "no-cache",
  });

  const handleSearch = (e) => {
    e.preventDefault();
    if (title !== "") {
      setQueryTitle(`title:${title}*`);
      search();
    }
  };

  return (
    <Layout>
      <div className="container px-4 mx-auto my-8">
        <h1 className="lg:text-5xl  font-bold leading-tight text-3xl mb-8">
          Search
        </h1>
        <form>
          <label
            for="default-search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </div>
            <input
              type="search"
              id="default-search"
              className="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search products..."
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <button
              type="submit"
              onClick={handleSearch}
              className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2"
            >
              Search
            </button>
          </div>
        </form>
        {loading ? (
          <Loader />
        ) : data?.products.nodes.length > 0 ? (
          <div className="my-8">
            <div className="grid sm:grid-cols-1 content-center md:grid-cols-2 lg:grid-cols-3 gap-8">
              {data?.products &&
                data?.products.nodes.map((product) => (
                  <div
                    key={product.id}
                    className="w-full mx-auto bg-white rounded-lg border border-gray-200 shadow-md  "
                  >
                    <Link
                      to={`/catalog/${product.handle}`}
                      state={{ id: product.id }}
                    >
                      <RuntimeProductImage
                        className="rounded-t-lg"
                        storefrontProduct={product.featuredImage}
                        alt="Image"
                      />
                    </Link>

                    <div className="p-5">
                      <Link
                        to={`/catalog/${product.handle}`}
                        state={{ id: product.id }}
                      >
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                          {product.title}
                        </h5>
                      </Link>
                      <p className="mb-3 line-clamp-4 font-normal text-gray-700">
                        {product.description}
                      </p>

                      <p className="mb-2 text-lg font-bold tracking-tight text-gray-900">
                        {product.priceRange.minVariantPrice.amount ===
                        product.priceRange.maxVariantPrice.amount
                          ? `${product.priceRange.minVariantPrice.amount} ${product.priceRange.minVariantPrice.currencyCode}`
                          : ` Starting from: ${product.priceRange.minVariantPrice.amount} ${product.priceRange.minVariantPrice.currencyCode}`}
                      </p>

                      <Link
                        to={`/catalog/${product.handle}`}
                        state={{ id: product.id }}
                        className={`inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                          product.availableForSale
                            ? ""
                            : "pointer-events-none bg-red-600"
                        }`}
                      >
                        {product.availableForSale ? (
                          <div className="flex items-center">
                            View
                            <svg
                              aria-hidden="true"
                              className="ml-2 -mr-1 w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          "Sold out"
                        )}
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
            {data?.products.nodes && data?.products.pageInfo.hasNextPage && (
              <button
                className="text-white block mx-auto bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                onClick={() =>
                  fetchMore({
                    variables: {
                      cursor: cursor,
                    },
                    updateQuery(prev, { fetchMoreResult }) {
                      // Make a copy of existing data

                      let mergedData = {
                        ...prev.products,
                      };
                      // Merge nodes
                      mergedData = [
                        ...mergedData.nodes,
                        ...fetchMoreResult.products.nodes,
                      ];
                      // Update endCursor
                      setCursor(cursor);

                      return {
                        mergedData,
                        products: {
                          nodes: [...fetchMoreResult.products.nodes],
                          id: fetchMoreResult.products.nodes.id,

                          title: fetchMoreResult.products.nodes.title,

                          description:
                            fetchMoreResult.products.nodes.description,

                          featuredImage: {
                            altText:
                              fetchMoreResult.products.nodes.featuredImage
                                ?.altText,

                            originalSrc:
                              fetchMoreResult.products.nodes.featuredImage
                                ?.originalSrc,

                            width:
                              fetchMoreResult.products.nodes.featuredImage
                                ?.width,

                            height:
                              fetchMoreResult.products.nodes.featuredImage
                                ?.height,

                            url: fetchMoreResult.products.nodes.featuredImage
                              ?.url,
                          },
                          availableForSale:
                            fetchMoreResult.products.nodes.availableForSale,

                          compareAtPriceRange: {
                            minVariantPrice: {
                              amount:
                                fetchMoreResult.products.nodes
                                  .compareAtPriceRange?.minVariantPrice.amount,
                            },
                            maxVariantPrice: {
                              amount:
                                fetchMoreResult.products.nodes
                                  .compareAtPriceRange?.maxVariantPrice.amount,
                            },
                          },
                          priceRange: {
                            //...fetchMoreResult.products.nodes.priceRange,
                            minVariantPrice: {
                              amount:
                                fetchMoreResult.products.nodes.priceRange
                                  ?.minVariantPrice.amount,

                              currencyCode:
                                fetchMoreResult.products.nodes.priceRange
                                  ?.minVariantPrice?.currencyCode,
                            },
                            maxVariantPrice: {
                              amount:
                                fetchMoreResult.products.nodes.priceRange
                                  ?.maxVariantPrice.amount,

                              currencyCode:
                                fetchMoreResult.products.nodes.priceRange
                                  ?.maxVariantPrice?.currencyCode,
                            },
                          },

                          pageInfo: {
                            endCursor:
                              fetchMoreResult.products.pageInfo.endCursor,

                            hasNextPage:
                              fetchMoreResult.products.pageInfo.hasNextPage,
                          },
                        },
                      };
                    },
                  })
                }
              >
                Load More
              </button>
            )}
          </div>
        ) : (
          <p className="text-center my-8">No results found</p>
        )}
      </div>
    </Layout>
  );
};

export const Head = () => <title>Search</title>;

export default Search;
